.modal {
  position: fixed;
  top: 0;
  left: 0;
  width:100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
}

.modal-prompt {
  @extend .card;
  display: flex;
  position:fixed;
  margin: 0;
  padding: 0;
  width: 50%;
  height: auto;
  max-height: 100vh;
  top:50%;
  left:50%;
  transform: translate(-50%,-50%);
}

.modal-pane {
  @extend .card;
  border-radius: 0px;
  position:fixed;
  display: flex;
  margin: 0;
  padding: 0;
  width: 45%;
  height: 100%;
  right: 0;
}


.display-block {
  display: block;
}

.display-none {
  display: none;
}

.modal-body{
  @extend .card-body;
  max-height: 600px;
  min-height: 100px;
  overflow-y: auto;
  &::-webkit-scrollbar{
    display: none;
  }
}

.modal-pane > .modal-body{
  max-height: 100vh;
  
}

body:has(.modal.display-block) {
  overflow:hidden;
  padding-right: 15px;

}