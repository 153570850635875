.spotlayout-container {
    display: inline-block;
    width: 100%;
    height: 80vh;
    overflow: scroll;
}

.spotlayout {
    @extend .position-relative;
    @extend .ms-auto;
    @extend .me-auto;
}

.spotlayout-item {
    @extend .card;
    @extend .m-1;
    border-radius: 0.5rem;

    &-terminal {
        @extend .move-on-hover;
        @extend .bg-secondary;
    }

    &-normal {
        @extend .move-on-hover;
        @extend .bg-primary;
    }

    &-highlighted {
        @extend .move-on-hover;
        @extend .bg-warning;
    }
    &-occupied {
        @extend .move-on-hover;
        @extend .bg-info;
    }
    &-selected {
        @extend .move-on-hover;
        @extend .bg-success;
    }

    &-disabled {
        @extend .bg-danger;
    }
}
